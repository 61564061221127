// load sass
import '../sass/common.scss';
// modernizr // webp
// import {modernizer}  from "./common/modernizr.js";
// modernizer();
// // load imageSwitch
// import {imageSwitch} from "./common/sub_imageswtch.js";
// imageSwitch();

// WPA バージョン管理用

window.addEventListener('load', () => {

});
// END SMART CONTENT MENU.
